.create-user-paper {
  margin: 0 auto;
  padding: 25px 20px;
}
.grid-2 {
  display: flex;
  justify-content: flex-start;
}
hr {
  margin: 4% 0 1% 0;
  opacity: 0.4;
}
label.input-label {
  text-transform: uppercase;
  margin-bottom: 15px;
}
#create-user-top-wrapper .heading-bar {
  width: 93%;
  margin: 2% auto 1% auto;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.createUserContainer
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium {
  top: 0px;
  margin-left: 4px;
}
.error-msg {
  font-size: 13px;
  color: red;
  margin-top: 5px;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.createUserContainer
  .MuiGrid-root.MuiGrid-grid-md-3.ErrorMessageGrid {
  width: 80%;
  top: 8%;
  z-index: 2500;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ErrorMessageGrid .global-error-msg {
  min-width: fit-content;
  height: 30px;
  font-family: Nunito;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  background: #ff0000b3;
  color: white;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 16px;
  align-items: center;
}

.description-wrapper .MuiInputBase-formControl.MuiInputBase-multiline {
  max-height: 220px;
  margin-top: 10px;
}
.description-wrapper.MuiGrid-root.MuiGrid-item {
  padding-right: 0;
}
.Status-box fieldset.MuiFormControl-root {
  padding-left: 30px;
}

body.worldline .save-btn.Mui-disabled {
  background-color: rgb(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.Status-box fieldset.MuiFormControl-root label span.MuiFormControlLabel-label {
  font-size: 15px;
}
body.worldline .persistent-dropdown {
  margin-top: 10px;
  width: 100%;
  height: 223px;
}
body.worldline .persistent-dropdown {
  margin-top: 10px;
  width: 100%;
}
body.worldline .persistent-dropdown.half-length {
  margin-top: 10px;
  width: 100%;
  height: 223px;
}
body.worldline .persistent-dropdown.full-length {
  margin-top: 10px;
  width: 100%;
  height: 286px;
}
body.worldline .persistent-dropdown .dropdown-content {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  height: 100%;
}
body.worldline .persistent-dropdown ul div[role="button"] {
  padding: 2px 10px;
}
body.worldline .persistent-dropdown ul div[role="button"] div {
  margin: 0;
}
body.worldline .product-container div.product-select select {
  padding: 10px;
  height: 100%;
  min-height: 197px;
}
.MuiFormGroup-root.Statusbutton svg {
  width: 20px;
  height: 20px;
}
body.worldline .persistent-dropdown ul div[role="button"] span:hover {
  text-decoration: underline;
  text-decoration-color: rgba(0, 153, 255, 0.5);
}
.create-user-paper .MuiGrid-container .toolbar-btn {
  display: flex;
  justify-content: flex-end;
  max-height: 40px;
  max-width: 130px;
}
.create-user-paper .MuiGrid-container .toolbar-btn.cancel-btn {
  max-width: 100px;
}
.orgCreateUserPartialRounded .MuiOutlinedInput-root {
  border-radius: 0 4px 4px 0px;
}
.orgCreateUserRounded .MuiOutlinedInput-root {
  border-radius: 4px;
}
.required-star {
  opacity: 0.8;
}
.duallist-fullscreen {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100% !important;
  background-color: #fff;
  z-index: 100; /* Ensure it's on top of other elements */
}
.react-dual-listbox button,
.react-dual-listbox select {
  line-height: 1.42857;
  font-family: inherit;
  border-radius: 5px;
}
.react-dual-listbox {
  background-color: #e8f6ff;
  padding: 10px;
  margin-top: 10px;
}
.react-dual-listbox select {
  min-height: 160px !important;
  border-radius: 2px;
}

.rdl-btn {
  margin: 0 3px;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
}

.rdl-btn:active:not(:disabled),
.rdl-btn:focus:not(:disabled) {
  border-color: #0099ff !important;
  background: #e6e6e6;
}
#create-user-top-wrapper .dual-list-box-roles-grid {
  flex-basis: 100%;
  max-width: 100%;
}
.product-based-roles .react-dual-listbox .rdl-no-options {
  color: transparent;
  padding: 10px;
  height: 100%;
  border: 1px solid #80808052;
  border-radius: 4px;
  background-color: #fff;
  min-height: 160px;
}
.product-based-roles .react-dual-listbox .rdl-no-options::before {
  color: grey;
  font-style: italic;
}
#create-user-top-wrapper .roles-container.product-based-roles .react-dual-listbox .rdl-no-options::before {
  content: "Please select an organization and a product to see the available roles." !important;
}
#create-user-top-wrapper .entities-container.product-based-roles .react-dual-listbox .rdl-no-options::before {
  content: "Please select an organization and a product to see the available entities." !important;
}

body.psa.worldline .persistent-dropdown div.selected span svg {
  vertical-align: bottom;
}
/*PSA THEME*/
body.psa.worldline {
  width: 100%;
}
body.psa.worldline .rdl-btn {
  border: 1px solid #0099ff;
  color: #0099ff;
}
body.psa.worldline .MuiFormGroup-root.Statusbutton .InactiveStatus svg {
  color: rgb(0, 153, 255);
}
body.psa.worldline .rdl-btn:hover:not(:disabled) {
  border-color: #0099ff;
  background: #e6e6e6;
}
body.psa.worldline .persistent-dropdown div.selected {
  background: #1976d20a;
}
body.psa.worldline .persistent-dropdown div.selected span {
  color: (var(--psa-user-name-link-color));
  font-weight: 400;
}
body.psa.worldline .add-another-group label,
body.psa.worldline span.add-another-check {
  color: #0099ff;
}
body.psa.worldline .toolbar-btn.cancel-btn button {
  color: #d32f2f;
  background-color: transparent;
  border: 1px solid rgba(211, 47, 47, 0.5);
}
body.psa.worldline .toolbar-btn.cancel-btn button:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(211, 47, 47, 0.04);
  border: 1px solid #d32f2f;
}

body.psa.worldline .save-btn {
  border-radius: 20px;
  padding: 0 30px;
  background-color: #0099ff;
  color: rgb(255, 255, 255);
}
body.psa.worldline .save-btn:hover {
  background-color: #0099ff;
}
body.psa.worldline .react-dual-listbox select {
  border: 1px solid #0099ff;
}

/*default theme*/
body.worldline span.add-another-check,
body.worldline .add-another-group label {
  color: #46beaa;
}
.rdl-btn:hover:not(:disabled) {
  border-color: #46beaa;
  background: #e6e6e6;
}
body.worldline .toolbar-btn.cancel-btn button {
  color: rgb(0, 0, 0);
  background-color: rgb(245, 195, 200);
  border: none;
}
body.worldline .save-btn {
  border-radius: 20px;
  padding: 0 30px;
  background-color: #46beaa;
  color: rgb(0, 0, 0);
}
body.worldline .save-btn:hover {
  border-radius: 20px;
  padding: 0 30px;
  background-color: #46beaa;
}
body.worldline .react-dual-listbox select {
  border: 1px solid #46beaa;
}
body.worldline .rdl-btn {
  border: 1px solid #46beaa;
  color: #46beaa;
}
body.worldline .MuiFormGroup-root.Statusbutton .InactiveStatus svg {
  color: #46beaa;
}
